<template>
  <div class="invite-wrapper">
    <div class="invite-title">{{ $t('team_manager.invitation_received') }}</div>
    <!-- <div class="invite-btn__wrapper">
      <el-button @click="handleCreate">创建团队</el-button>
    </div> -->
    <div
      class="content-wrapper"
      v-loading="isLoading"
      :element-loading-text="$t('loading')"
      element-loading-background="var(--report-analyse-mask)"
    >
      <div class="list-wrap">
        <el-table :data="tableData" @row-click="handleClick" :row-style="getRowStyle">
          <el-table-column prop="id" :label="$t('team_manager.index')" min-width="10%" />
          <el-table-column prop="team_name" :label="$t('team_manager.team_name')" min-width="30%" :show-overflow-tooltip="true" />
          <el-table-column prop="inviter" :label="$t('team_manager.team_owner')" min-width="20%" />
          <el-table-column prop="time" :label="$t('team_manager.receive_time')" min-width="20%" />
          <el-table-column prop="option" :label="$t('order_manager.action')" min-width="20%">
            <template #default="scope">
              <!-- <template v-if="scope.row.is_read">
                <template v-if="scope.row.message_state === 1">已失效</template>
                <template v-else-if="scope.row.message_state === 2">已接受</template>
                <template v-else-if="scope.row.message_state === 3">已拒绝</template>
              </template>
              <template v-else>
                <template v-if="scope.row.message_type === 0">
                  <span class="invite-btn" @click="acceptJoin(scope.row)">接受</span>
                  <span class="invite-btn" @click="denyJoin(scope.row)">拒绝</span>
                </template>
              </template> -->
              <template v-if="scope.row.invited_state === 1">
                <span class="invite-btn" @click="acceptJoin(scope.row)">{{ $t('team_manager.accept') }}</span>
                <span class="invite-btn" @click="denyJoin(scope.row)">{{ $t('team_manager.reject') }}</span>
              </template>
              <template v-else-if="scope.row.invited_state === 2">{{ $t('team_manager.accepted') }}</template>
              <template v-else-if="scope.row.invited_state === 3">{{ $t('team_manager.rejected') }}</template>
              <template v-else-if="scope.row.invited_state === 4">{{ $t('team_manager.quited') }}</template>
              <template v-else-if="scope.row.invited_state === 5">{{ $t('team_manager.expired') }}</template>
            </template>
          </el-table-column>
          <template #empty>
            <div class="empty">
              <sandNoData :text="$t('tip.noData')" />
            </div>
          </template>
        </el-table>
      </div>
      <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
        />
      </div>
    </div>
    <el-dialog
      v-model="upgradeVisible"
      :title="$t('team_manager.invitation_dialog_title')"
      width="440px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('team_manager.invitation_dialog_content') }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="toUpgrade">{{ $t('subscription_manager.upgrade') }}</el-button>
          <el-button @click="toPrice"
            >{{ $t('action.show_detail') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useState } from '@/hooks/useVuex'
import emitter from '@/app/emitter.js'
// import upgradeDialog from '@/components/common/upgradeDialog.vue'
import { postConsumeMesssage, getTeamMessage } from '@/services/notice'
import Pagination from '@/components/common/Pagination'
// import addTeamDialog from './components/add-team-dialog.vue'
import { message } from '@/hooks/useUI'
import dayjs from 'dayjs'
import SandNoData from '@/components/report/sandNoData'
import { t } from 'app/i18n'
const router = useRouter()
const tableData = ref([])
const upgradeVisible = ref(false)
const isLoading = ref(false)
const currentPage = ref(1)
const totalSize = ref(1)
const pageSize = ref(50)
// const isShowUpgrade = ref(false)
// const visible = ref(false)
const { userInfo } = useState('user', ['userInfo'])

onMounted(() => {
  getData()
})

// const handleCreate = () => {
//   if (userInfo.value.group === 'free') {
//     isShowUpgrade.value = true
//     return
//   }
//   visible.value = true
// }

const handleSizeChange = (size) => {
  pageSize.value = size
  handleCurrentChange(1)
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getData()
}

const getRowStyle = (row) => {
  if (row.row.invited_state === 2) {
    return {
      cursor: 'pointer'
    }
  } else {
    return {
      cursor: 'not-allowed'
    }
  }
}

const getData = () => {
  isLoading.value = true
  getTeamMessage({
    page: currentPage.value,
    size: pageSize.value
  }).then(res => {
    isLoading.value = false
    totalSize.value = res.total
    tableData.value = res.data.map((item) => {
      return {
        ...item,
        time: dayjs(item.added_on).format('YYYY-MM-DD HH:mm:ss')
      }
    })
  })
}

const acceptJoin = (msg) => {
  postConsumeMesssage({
    team_id: msg.team_id,
    invited_state: 2
  }).then((res) => {
    if ('code' in res) {
      if (res.code === 1 && (userInfo.value.group === 'free' || userInfo.value.group === 'professional')) {
        upgradeVisible.value = true
        // commitMsg('加入团队配额已满，请升级至专业版或专业增强版获得更多配额')
      } else {
        message(res.message, 'error')
      }
    } else {
      message(t('action.handle'))
    }
    emitter.emit('reloadNotice')
    // this.$store.dispatch('notice/getMessageList')
    getData()
  })
}

const handleClick = (row) => {
  // 执行跳转
  if (row.invited_state === 2) {
    router.push({
      name: 'ownerteam',
      params: {
        team_id: row.team_id
      }
    })
  }
}

const denyJoin = (msg) => {
  postConsumeMesssage({
    team_id: msg.team_id,
    invited_state: 3
  }).then((res) => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.handle'))
    }
    emitter.emit('reloadNotice')
    // this.$store.dispatch('notice/getMessageList')
    getData()
  })
}

// const closeAddTeam = () => {
//   visible.value = false
// }

// const submitTeam = (formData) => {
//   // emit('add', formData)
//   visible.value = false
//   router.push({
//     name: 'ownerteam'
//   })
// }

const toPrice = () => {
  window.open('/price', '_blank')
}

const toUpgrade = () => {
  const group = userInfo.value.group
  if (!group) {
    emitter.emit('showLogin')
    return
  }
  let query = ''
  if (userInfo.value.group === 'free') {
    query = '/usercenter/subscribe?type=purchase&price_type=one_year'
    // query.type = 'purchase'
    // query.price_type = 'one_year'
  } else if (userInfo.value.group === 'professional') {
    // query.type = 'upgrade'
    // query.group = 'enterprise'
    query = '/usercenter/subscribe?type=upgrade&group=enterprise'
  }
  window.open(query, '_blank')
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.invite-wrapper {
  padding: 0 24px;
  min-height: calc(100% - 85px);
  padding-bottom: 24px;
  .invite-title {
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
  }
  .invite-btn__wrapper {
    padding-bottom: 15px;
  }
  .content-wrapper {
    background-color: var(--detect-config-bg);
    border-radius: 2px;
    min-height: calc(100vh - 193px);
    box-sizing: border-box;
    padding: 24px 0;
    .list-wrap {
      padding: 0 24px;
      .invite-btn {
        font-size: 14px;
        cursor: pointer;
        padding: 2px 0;
        color:  var(--color-priamry);
        img {
          vertical-align: middle;
          margin-right: 4px;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
        // &:hover {
        //   background: #445186;
        //   border-radius: 3px;
        // }
      }
    }
    .pagination-wrap {
      padding: 10px 3px 24px;
      text-align: right;
    }
    :deep(.el-table__row) {
      height: 48px;
    }
    :deep(.el-table__header) {
      height: 48px;
      .el-table__cell {
        background-color: var(--color-bg-3);
      }
    }
  }
  .empty {
    height: calc(100vh - 322px);
  }
}
</style>
